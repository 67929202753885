import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CategoriesPage } from './categories/categories.page';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsPageModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'order-detail',
    loadChildren: () =>
      import('./order-detail/order-detail.module').then(
        (m) => m.OrderDetailPageModule
      ),
  },
  {
    path: 'cancelledorders',
    loadChildren: () =>
      import('./cancelledorders/cancelledorders.module').then(
        (m) => m.CancelledordersPageModule
      ),
  },
  {
    path: 'menu',
    loadChildren: () =>
      import('./menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'adjusted-orders',
    loadChildren: () =>
      import('./adjusted-orders/adjusted-orders.module').then(
        (m) => m.AdjustedOrdersPageModule
      ),
  },
  {
    path: 'ready-orders',
    loadChildren: () =>
      import('./ready-orders/ready-orders.module').then(
        (m) => m.ReadyOrdersPageModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchPageModule),
  },
  {
    path: 'stock-out',
    loadChildren: () =>
      import('./stock-out/stock-out.module').then((m) => m.StockOutPageModule),
  },
  {
    path: 'reporting-filter',
    loadChildren: () =>
      import('./Reporting/reporting-filter/reporting-filter.module').then(
        (m) => m.ReportingFilterPageModule
      ),
  },
  {
    path: 'reporting-filter/:id',
    loadChildren: () =>
      import('./Reporting/reporting-filter/reporting-filter.module').then(
        (m) => m.ReportingFilterPageModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./Reporting/reports/reports.module').then(
        (m) => m.ReportsPageModule
      ),
  },
  {
    path: 'loyality',
    loadChildren: () =>
      import('./loyality/loyality.module').then((m) => m.LoyalityPageModule),
  },
  {
    path: 'user-rewards',
    loadChildren: () =>
      import('./user-rewards/user-rewards.module').then(
        (m) => m.UserRewardsPageModule
      ),
  },
  {
    path: 'point-system',
    loadChildren: () =>
      import('./point-system/point-system.module').then(
        (m) => m.PointSystemPageModule
      ),
  },
  {
    path: 'punch-cards',
    loadChildren: () =>
      import('./punch-cards/punch-cards.module').then(
        (m) => m.PunchCardsPageModule
      ),
  },
  {
    path: 'redeem-rewards',
    loadChildren: () =>
      import('./redeem-rewards/redeem-rewards.module').then(
        (m) => m.RedeemRewardsPageModule
      ),
  },
  {
    path: 'gift-cards',
    loadChildren: () =>
      import('./gift-cards/gift-cards.module').then(
        (m) => m.GiftCardsPageModule
      ),
  },
  {
    path: 'buy-points',
    loadChildren: () =>
      import('./buy-points/buy-points.module').then(
        (m) => m.BuyPointsPageModule
      ),
  },
  {
    path: 'buy-giftcard',
    loadChildren: () =>
      import('./buy-giftcard/buy-giftcard.module').then(
        (m) => m.BuyGiftcardPageModule
      ),
  },
  {
    path: 'support',
    loadChildren: () =>
      import('./support/support.module').then((m) => m.SupportPageModule),
  },

  {
    path: 'support-callback',
    loadChildren: () =>
      import('./support-callback/support-callback.module').then(
        (m) => m.SupportCallbackPageModule
      ),
  },
  {
    path: 'branches',
    loadChildren: () =>
      import('./branches/branches.module').then((m) => m.BranchesPageModule),
  },
  {
    path: 'chat-list',
    loadChildren: () =>
      import('./chat-list/chat-list.module').then((m) => m.ChatListPageModule),
  },
  {
    path: 'kitchen-display',
    loadChildren: () =>
      import('./kitchen-display/kitchen-display.module').then(
        (m) => m.KitchenDisplayPageModule
      ),
  },
  {
    path: 'advanced-settings',
    loadChildren: () =>
      import('./advanced-settings/advanced-settings.module').then(
        (m) => m.AdvancedSettingsPageModule
      ),
  },
  {
    path: 'date-selection',
    loadChildren: () =>
      import('./date-selection/date-selection.module').then(
        (m) => m.DateSelectionPageModule
      ),
  },

  {
    path: 'chowly-menu',
    loadChildren: () =>
      import('./menu/menu.module').then((m) => m.MenuPageModule),
  },
  {
    path: 'create-super-category',
    loadChildren: () =>
      import(
        './create-new-manu-item/create-super-category/create-super-category.module'
      ).then((m) => m.CreateSuperCategoryPageModule),
  },
  {
    path: 'create-category',
    loadChildren: () =>
      import(
        './create-new-manu-item/create-category/create-category.module'
      ).then((m) => m.CreateCategoryPageModule),
  },
  {
    path: 'create-item',
    loadChildren: () =>
      import('./create-new-manu-item/create-item/create-item.module').then(
        (m) => m.CreateItemPageModule
      ),
  },
  {
    path: 'payments-only',
    loadChildren: () =>
      import('./payments-only/payments-only.module').then(
        (m) => m.PaymentsOnlyPageModule
      ),
  },
  {
    path: 'staff',
    loadChildren: () => import('./staff-login/staff-login.module').then( m => m.StaffLoginPageModule)
  },
  {
    path: 'photomodal',
    loadChildren: () => import('./photomodal/photomodal.module').then( m => m.PhotomodalPageModule)
  },
  {
    path: 'photo',
    loadChildren: () => import('./photo/photo.module').then( m => m.PhotoPageModule)
  },
  {
    path: 'landingpage',
    loadChildren: () => import('./landingpage/landingpage.module').then( m => m.LandingpagePageModule)
  },
  {
    path: 'generate-report',
    loadChildren: () => import('./generate-report/generate-report.module').then( m => m.GenerateReportPageModule)
  },
  {
    path: 'dayclose-report',
    loadChildren: () => import('./dayclose-report/dayclose-report.module').then( m => m.DaycloseReportPageModule)
  },
  {
    path: 'custom-report',
    loadChildren: () => import('./custom-report/custom-report.module').then( m => m.CustomReportPageModule)
  },
  {
    path: 'day-snap',
    loadChildren: () => import('./day-snap/day-snap.module').then( m => m.DaySnapPageModule)
  },
  {
    path: 'tip-by-staff',
    loadChildren: () => import('./tip-by-staff/tip-by-staff.module').then( m => m.TipByStaffPageModule)
  },  {
    path: 'add-new-email',
    loadChildren: () => import('./add-new-email/add-new-email.module').then( m => m.AddNewEmailPageModule)
  },
  {
    path: 'update-email-modal',
    loadChildren: () => import('./update-email-modal/update-email-modal.module').then( m => m.UpdateEmailModalPageModule)
  },
  {
    path: 'timesheet',
    loadChildren: () => import('./timesheet/timesheet.module').then( m => m.TimesheetPageModule)
  },
  {
    path: 'category-summary',
    loadChildren: () => import('./category-summary/category-summary.module').then( m => m.CategorySummaryPageModule)
  },
  {
    path: 'timesheet-details',
    loadChildren: () => import('./timesheet-details/timesheet-details.module').then( m => m.TimesheetDetailsPageModule)
  },
  {
    path: 'nsr',
    loadChildren: () => import('./nsr/nsr.module').then( m => m.NsrPageModule)
  },
  {
    path: 'addnewquest',
    loadChildren: () => import('./addnewquest/addnewquest.module').then( m => m.AddnewquestPageModule)
  },
  {
    path: 'editquestionnaire',
    loadChildren: () => import('./editquestionnaire/editquestionnaire.module').then( m => m.EditquestionnairePageModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('./branch/branch.module').then( m => m.BranchPageModule)
  },
  {
    path: 'add-update-branch',
    loadChildren: () => import('./add-update-branch/add-update-branch.module').then( m => m.AddUpdateBranchPageModule)
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
